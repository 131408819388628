:root {
  --clr-accent: rgb(66, 184, 221);
  --clr-danger: crimson;
  --clr-neutral-100: #eeeeee;
  --clr-neutral-300: #cccccc;
  --clr-neutral-500: #999999;
  --clr-neutral-700: #666666;
  --clr-neutral-900: #333333;

  --spacer: 1rem;
  --radius: 0.8rem;
}
